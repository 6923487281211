@charset "utf-8";

$border-color: #eaeaea !default;
$footer-background-color: #eaeaea !default;
$text-color: #545454 !default;

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

.text-muted { color: $muted-text-color; }

html {
  font-size: 12px;
  @include breakpoint($medium) { font-size: 14px; }
  @include breakpoint($large) { font-size: 16px; }
  @include breakpoint($x-large) { font-size: 18px; }
}

.feature__item {
  &--col-2 {
    @extend .feature__item;
    @include breakpoint($small) {
      width: span(6 of 12);
      &:nth-child(2n + 1) {
        clear: both;
        margin-left: 0;
      }

      &:nth-child(2n + 2) {
        clear: none;
        margin-left: gutter(of 12);
      }
    }
  }
}
